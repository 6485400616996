import { http } from "../common/http";

const getToken = () => {
    return localStorage.getItem("token");
  // return sessionStorage.getItem("token");
};

export const createUploadFile = async (bucketname, file, body) => {
  const token = getToken();
    try {
    return await http.put(
      // `${process.env.REACT_APP_API_ADMIN_UPLOAD}${bucketname}/${filename}`,
      `${process.env.REACT_APP_API_ADMIN_UPLOAD}inbound/${file}`,
      {
        // "x-api-key": process.env.REACT_APP_API_KEY,
        Authorization: `Bearer ${token}`,
        "Content-Type": 'multipart/form-data'
      },
      body
    );
  } catch (err) {
    console.error(err);
  }
};




