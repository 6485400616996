import { http } from "../common/http";

const getToken = () => {
     return localStorage.getItem("token");
  // return sessionStorage.getItem("token");
};

export const getCoverage = (path) => {
  const token = getToken();
  return fetch(`https://api.dev.facet.it.ucla.edu/coverage${path}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "text/html",
    },
  }).then(async (res) => {
    const data = await res.arrayBuffer();
    const html = await new TextDecoder("utf-8").decode(new Uint8Array(data))
    return  html;
  });
};

