export const ROUTES = {
  APP_ROOT: '/',
  DASHBOARD: '/dashboard',
  LOGIN: '/login',
  // CLASSES: '/control-table',
  ADV_SEARCH: '/adv-search',
  ADMIN_FORMS: '/admin',
  FUNCTIONAL_ADMIN: '/functional-admin',
  MONITORING_DASHBOARDS: '/monitoring-dashboards',
  COVERAGE: '/coverage',
  ERROR: '/error',
};
