import { useEffect, useState } from "react";
import { getCoverage } from "../../services/coverageService";
import  './sort.js';
// import './prettify.js';
import "./index.scss";

const Coverage = () => {

  const [coverage, setCoverage] = useState<any>();
  const [rootPath, setRootPath] = useState();

  async function fetchgetCoverageList(path = "/index.html") {
    try {
      const data = await getCoverage(path);
      // if (data) {
        setCoverage(data);
      // }
    } catch (e) {
      console.error("Something went wrong!", e)
    }
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = require("./sort.js");
    // script.src = require("./prettify.js")
    script.async = true;
    document.body.appendChild(script);
    fetchgetCoverageList();
    return() => {
      document.body.removeChild(script);
    }
  }, []);

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.target.href) {
      let path = e.target.href.replace(window.location.origin, '')
      console.log("url ==> ", path, "\n", e.target.href)
      if (path) {
        if (path.toLowerCase().includes("/index.html")) {
          setRootPath(path.toLowerCase().replace("/index.html", ""));
        } else if (path !== "/jacoco-sessions.html"){
          path = rootPath + path;
        }
        console.log(path);
        fetchgetCoverageList(path)
      }
    }
  }


  return (
   <div className="coverage">
      <div dangerouslySetInnerHTML={{__html: coverage || "Coverage"}} onClick={handleClick}></div>
    </div>
  );
};
export default Coverage;
