import { GET_JOBS, GET_TRANSACTIONS , GET_STUDENTINTIATION, GET_VUGTRANSACTIONS } from "../actions/types";

const initialState = {
  jobs: [],
  transactions: [],
  studentintiation: [],
  vugTransactions: []
};

const employeesReducer = (state = initialState, action: any) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOBS: {
      const newState = { jobs: payload };
      return { ...state, ...newState };
    }
    case GET_TRANSACTIONS: {
      const newState = { transactions: payload };
      return { ...state, ...newState };
    }
    case GET_STUDENTINTIATION: {
      const newState = { studentintiation: payload };
      return { ...state, ...newState };
    }
    case GET_VUGTRANSACTIONS : {
      const newState = { vugTransactions: payload };
      return { ...state, ...newState };
    }
    default:
      return state;
  }
};

export default employeesReducer;
