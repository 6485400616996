import { CSVLink } from "react-csv";
import { Button } from "react-bootstrap";
import styles from "./index.module.scss";
import { DownloadIcon} from "@heroicons/react/outline";

const ExcelDownload = (props: any) => {

  console.log("props==> ", props)
  //  ${props.iconClassName || ''}
  return (
    <>
      {props.isVisible ? (
        <div className={`${styles.download_icon} ${props.className || ''}`}>
          <Button className={`${styles.download_btn}`}>
            <CSVLink data={props.data} filename={props.filename || "File"}>
              <DownloadIcon />
            </CSVLink>
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default ExcelDownload;
