  
  export function generateQueryString(payload: any) {
    if (!!payload) {
        const pairs = Object.entries(payload);
        if (pairs.length) {
          return pairs.reduce((acc, item) => {
            const [key, value] = item;
            if (value) {
              acc = acc.concat(`${key}=${value}&`);
              return acc;
            } else {
              return acc;
            }
          }, "");
        } else return "";
    } else {
        return "";
    }
  }