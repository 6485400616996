export function getUniqueTableColValuesMap(tableRowsData = [], cols = []) {
  const colMap = new Map();
  return new Promise((res) => {
    tableRowsData.reduce((acc, row, index) => {
      if (index === tableRowsData.length - 1) {
        setTimeout(() => res(colMap), 200);
      }
      cols.forEach((col) => {
        let value = null;
        if (acc.has(col)) {
          value = acc.get(col);
        } else {
          value = new Set();
        }
        value.add(row[col]);
        acc.set(col, value);
      });
      return acc;
    }, colMap);
  });
}

export function filterRowsDataInCols(filterObj = {}, tableRowsData = []) {
  const colNames = Object.keys(filterObj).reduce((acc, colName) => {
    if (!!filterObj[colName] && filterObj[colName].length) {
      acc.push(colName);
    }
    return acc;
  }, []);
  return new Promise((res) => {
    let data = tableRowsData;
    if (colNames.length) {
      data = tableRowsData.filter((row, index) => {
        if (index === tableRowsData.length - 1) {
          setTimeout(() => res(data), 100);
        }
        return !!colNames.every((col) => {
          if (Array.isArray(filterObj[col])) {
            return filterObj[col].map(cl => cl.value).includes(row[col]);
          } else {
            return row[col]?.includes(filterObj[col]);
          }
        });
      });
    } else {
      res(data);
    }
  });
}
