import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";
import UCLA from "../Assets/UCLA.jpg";
import { fetchToken } from "../../services/tokenService";

const AUTHORISED_USERS = [
  // {
  //   username: "rrbqa056a15s19mmru0p04lhs",
  //   password: "1613mnnrvjtp4af2hnpi4fp8ca2asp934vb2g8212rotch6k6do0"
  // }
];

//const isUserAuthorised = ({username,password})=>{
//return !!AUTHORISED_USERS.filter(user => user.username === username && user.password === password).length;
//}
const Login = () => {
  const history = useHistory();

  const redirectUser = () => {
    history.push("/");
  };

  const submitForm = async (e) => {
    e.preventDefault();
    console.log("===Here");
    const username = e.target.username.value;
    const password = e.target.password.value;
    try {
      const response = await fetchToken({
        client_id: username,
        client_secret: password,
      });
      if (response?.error) {
        window.alert(
          "Invalid username password combination. Please try again !"
        );
      } else {
        if (response.access_token) {
            localStorage.setItem("token", response.access_token);
          // sessionStorage.setItem("token", response.access_token);
        }
        redirectUser();
      }
    } catch (e) {
      console.log(e);
    }

    // if (isUserAuthorised({username,password})) {

    //} else {
    //window.alert("Invalid username/password combination");
    //}
  };
  return (
    <div className={styles.login_page}>
      <div className={styles.login_wrapper}>
        <div className={` text-center  ${styles.login_title} `}>
          FACET Dashboard - Temp Login
        </div>
        {/* <img src={UCLA} alt="UCLA" className={styles.logo} /> */}
        <form onSubmit={submitForm}>
          <div className="mb-3">
            <label className="form-label">Username</label>
            <input
              type="text"
              name="username"
              className="form-control"
              placeholder="Enter username"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Password</label>
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Enter Enter password"
            />
          </div>
          <input
            type="submit"
            value="Login"
            className={`btn btn-sm text-center ${styles.login_btn}`}
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
