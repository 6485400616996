
import CustomTabs from "../../components/CustomTabs";
import StudentInitation  from "./StudentInitation";
import { ControlFlags } from "./ControlFlagsTable";
import  SSD  from "./SSD";
import Classes from "../Classes";
import { SafiBar } from "../FunctionalAdmin/SafiBarFlagsTable";





const SAFI = () => {
  console.log(123)
  return <h2>SAFI TAB</h2>
}



const tabs =  [
  // {title: 'SI', eventKey: 'si', Component: StudentInitation},
  //  {title: 'SSD', eventKey: 'ssd', Component: SSD},
  // {title: 'SAFI', eventKey: 'safi',Component: SAFI},
  {title: 'Control Flags Table', eventKey: 'control flag table',Component: ControlFlags},
  {title: 'Control Table', eventKey: 'control table',Component: Classes},
  // {title: 'BAR to SFP Fund Code Mapping', eventKey: 'Safi Bar table',Component: SafiBar},
]

const AdminForms  = ({  }) => {
  return (
    <CustomTabs defaultActiveKey="control flag table" tabs={tabs}/>
  );
};      
export default AdminForms