
import { http } from "../common/http";
import { fetchToken, refetchToken } from "./tokenService";

const getToken = () => {
     return localStorage.getItem("token");
  // return sessionStorage.getItem("token");
};

export const getControlledClasses = async () => {
  const token = getToken();
  let response;
  try {
    response = await http.get(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}controls`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (err) {
    console.error(err);
  }
  return response;
};

export const createControlledClasses = async (body) => {
  const token = getToken();
  try {
    return await http.post(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}control/create`,
      {
        Authorization: `Bearer ${token}`,
      },
      JSON.stringify(body)
      // body
    );
  } catch (err) {
    console.error(err);
  }
};

export const createControlledClass = async (body) => {
  const token = getToken();
  try {
    return await http.post(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}control/create`,
      {
        Authorization: `Bearer ${token}`,
      },
      // JSON.stringify(body)
      body
    );
  } catch (err) {
    console.error(err);
  }
};

export const getPermissions = async () => {
  const token = getToken();
  if(!token){
    await  refetchToken()
   }
  try {
    return await http.get(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}control/GROUPER_ROLES/FUNCTIONS`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (err) {
    console.error(err);
  }
};

export const updateControlledClass = async (body) => {
  const token = getToken();
  try {
    return await http.put(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}control/update`,
      {
        Authorization: `Bearer ${token}`,
      },
      JSON.stringify(body)
    );
  } catch (err) {
    console.error(err);
  }
};

export const getsafissd = async (year) => {
  const token = getToken();
  let response;
  try {
    response = await http.get(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}control/SAFI_SSD_CONTROLS/${year||'2024-2025'}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (err) {
    console.error(err);
  }
  return response;
};

export const getunexssd = async (year) => {
  const token = getToken();
  let response;
  try {
    response = await http.get(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}control/SAFI_SSD_CONTROLS_UNEX/${year||'2024-2025'}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (err) {
    console.error(err);
  }
  return response;
};

