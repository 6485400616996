import { Button, Row, Col, InputGroup, Dropdown } from "react-bootstrap";
import { Form } from "react-bootstrap";
import styles from "./index.module.scss";
import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import DashboardService from "../../services/dashboard";
import CustomTable, { CustomColumn } from "../../components/CustomTable";
import Modal from "../../components/Common/Modal";
import Menu from "../AdminForms/Menu";
import Select from "react-select";
import {
  FilterIcon,
  LockClosedIcon,
  SearchIcon,
  XCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { FilterIcon as FilterIconFilled } from "@heroicons/react/solid";
import {
  filterRowsDataInCols,
  getUniqueTableColValuesMap,
} from "../../common/utils";
import ExcelDownload from "../../components/Common/ExcelDownload";

const multiSelectFilterColsNames = [
  "uid",
  "message_status",
  "message_class",
  "job_type",
];

const debounce = (fn: Function, ms = 500) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

const Jobs = (props) => {
  const { passFilteredData, gridData: propData, ...rest } = props;
  // console.log("advsearch ==> ", rest, props);
  const [gridData, setData] = useState([]);
  const [flagsAdvData, setFlagsAdvData] = useState({});
  const [formError, setFormError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // const [showFilterModal, setShowFilterModal] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [showJobMessagesModal, setshowJobMessagesModal] = useState(null);
  const [colFilter, setColFilter] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [colValsMapData, setColValsMapData] = useState(new Map());
  const formRef = useRef(null);
  const ref = useRef();

  const renderSelColHeader = useCallback(
    (colName, col) => {
      const uniqId = col?.name;
      const uniqVals = colValsMapData.get(uniqId);
      return (
        <Row className="position-relative">
          <Col>{colName}</Col>
          <span className="position-absolute w-auto" style={{ right: 0 }}>
            <Menu
              id={colName.replace(/ /g, "").toLowerCase()}
              trigger={
                !!colFilter[uniqId] ? (
                  <FilterIconFilled width="16" />
                ) : (
                  <FilterIcon width="16" />
                )
              }
              // trigger={<FilterIcon width="16"  fill={props.filters[props.id] ? '#707070' : 'lightgray'} stroke={props.filters[props.id] ? '#707070' : ''} />}
              className={styles.menu}
            >
              <Select
                className={styles.filerInput}
                options={Array.from(uniqVals || new Set()).map((colVal) => ({
                  value: colVal,
                  label: colVal,
                }))}
                isMulti
                isClearable
                onChange={(val) => {
                  if (val.length) {
                    setColFilter((prev) => ({ ...prev, [uniqId]: val }));
                  } else {
                    setColFilter((prev) => {
                      delete prev[uniqId];
                      return { ...prev };
                    });
                  }
                }}
                value={colFilter[uniqId]}
              />
            </Menu>
          </span>
        </Row>
      );
    },
    [colFilter, colValsMapData]
  );

  const renderColHeader = useCallback(
    (colName, col) => {
      const uniqId = col?.name;
      return (
        <Row className="position-relative">
          <Col>{colName}</Col>
          <span className="position-absolute w-auto" style={{ right: 0 }}>
            <Menu
              id={colName.replace(/ /g, "").toLowerCase()}
              trigger={
                !!colFilter[uniqId] ? (
                  <FilterIconFilled width="16" />
                ) : (
                  <FilterIcon width="16" />
                )
              }
              className={styles.menu}
            >
              <InputGroup className={styles.filerInput} size="sm">
                <InputGroup.Text id={uniqId}>
                  <SearchIcon width="16" />
                </InputGroup.Text>
                <Form.Control
                  aria-label="Small"
                  aria-describedby={uniqId}
                  onChange={debounce(({ target: { value } }) =>
                    setColFilter((prev) => ({
                      ...prev,
                      [uniqId]: value.trim(),
                    }))
                  )}
                  value={colFilter[uniqId]}
                />
                <Button
                  onClick={() =>
                    setColFilter((prev) => {
                      delete prev[uniqId];
                      return { ...prev };
                    })
                  }
                >
                  <XIcon width="16" />
                </Button>
              </InputGroup>
            </Menu>
          </span>
        </Row>
      );
    },
    [colFilter]
  );

  useEffect(() => {
    const filterFn = async () => {
      const data = await filterRowsDataInCols(colFilter, gridData);
      setFilteredData(data);
    };
    filterFn();
  }, [gridData, colFilter]);

  async function fetchAdvFlags(title) {
    const jobId = "STUINIT_SIS_ALL_20230616212341585";
    const data = await DashboardService.searchTransactions({
      job_id: jobId,
      message_status: "Failed",
    });
    console.log(data);
    if (data) {
      setshowJobMessagesModal({
        title,
        data,
      });
    }
  }

  const columns1: Array<CustomColumn> = [
    { name: "uid", label: "UID", cellClass: `${styles.uid_col}` },
    {
      name: "message_class",
      label: "Transaction Message Class",
      cellClass: `${styles.message_class_col}`,
    },
    {
      name: "message_id",
      label: "Transaction Message ID",
      cellClass: `${styles.message_id_col}`,
    },
    {
      name: "message_errors",
      label: "Transaction Message Errors",
      cellClass: `${styles.error_col}`,
    },
    {
      name: "created_at",
      label: "Created At",
      cellClass: `${styles.created_at_col}`,
    },
  ];

  const columns: Array<CustomColumn> = [
    {
      name: "job_id",
      label: "Job ID",
      // headerClass: `${styles.text_ellipsis}`,
      width: 120,
      showInExcel: true,
    },
    {
      name: "job_type",
      label: "Job Type",
      width: 90,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      renderHeader: renderSelColHeader,
    },
    {
      name: "sis_records_loaded",
      label: "SIS Records",
      // headerClass: `${styles.text_ellipsis}`,
      width: 50,
      showInExcel: true,
    },
    {
      name: "job_status",
      label: "Job Status",
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      width: 50,
      renderRow: (value, row, index) => {
        return row.job_status == "Failed" ? (
          <a
          href="#"
          onClick={() => {
            if (row.job_status == "Failed") {
              fetchAdvFlags(`Job Details for : ${row.job_id}`);
            }
          }}
          style={{
            color: "red",
            fontWeight: "bold",
          }}
        >
          {value}
        </a>
          
        ) : (
          <>{value}</>
        );
      },
    },
    {
      name: "created_at",
      label: "Created At",
      width: 90,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      type: 'date',
      sortable: true,
    },
  ];

  useEffect(() => {
    async function fetchStudentJobs() {
      setIsLoading(true);
      const data = await DashboardService.getJobs();
      setIsLoading(false);
      setData(
        data
          .map((d) => ({ ...d, created_at: new Date(d.created_at).valueOf() }))
          .sort((a, b) => b.created_at - a.created_at)
          .map((d) => ({
            ...d,
            created_at: new Date(d.created_at).toISOString(),
          }))
      );
    }
    fetchStudentJobs();
    const data = [];
  }, []);

  useEffect(() => {
    const getColVals = async () => {
      const data = await getUniqueTableColValuesMap(
        gridData,
        multiSelectFilterColsNames
      );
      console.log("data ==> ", data);
      setColValsMapData(data);
    };
    getColVals();
  }, [gridData, multiSelectFilterColsNames]);

  const clearAllFilters = useCallback(() => {
    // setData([]);
    setColFilter({});
    setFilteredData([]);
    // setColValsMapData(new Map());
  }, []);

  return (
    <div className="">
      <div className={styles.clear_all}>
        <Button
          className={styles.clear_all_btn}
          size="sm"
          onClick={clearAllFilters}
        >
          Clear All Filters
        </Button>
      </div>
      <div className={styles.download_btn}>
        <ExcelDownload data={gridData} filename={"Jobs"} isVisible />
      </div>

      <div className="d-flex justify-content-center">
        <CustomTable
          parentClass={styles.fullTableWidth as any}
          isLoading={isLoading}
          rows={filteredData}
          columns={columns}
          ref={ref}
          // headerCellClass={styles.headerCellClass}
          // bodyCellClass={styles.cellClass}
          bordered
          rowsPerPage={process.env.REACT_APP_ROWS_PAGE}
          rowClass="test"
          // responsive
          striped
          title=""
          hasTableActions
          tableActionProps={{
            hasAddRow: false,
            hasSearch: false,
            hasDownload: false,
          }}
          pagination="default"
          lineHeight={"14px"}
          style={{ tableLayout: "fixed" }}
        />
      </div>
      {!!showJobMessagesModal && (
        <Modal
          handleClose={() => setshowJobMessagesModal(false)}
          className={styles.jobs_modal}
          size='xl'
          config={{
            title: showJobMessagesModal.title,
            footer: false,
            body: (
              <CustomTable
                rows={showJobMessagesModal.data.slice(0, 10)}
                columns={columns1}
                title={""}
                bordered
                striped
                tableActionProps={{
                  hasSearch: false,
                  hasAddRow: false,
                  hasDownload: false,
                }}
              />
            ),
          }}
        />
      )}
    </div>
  );
};
export default Jobs;
