import { Row, Card, Button, Stack, Col } from "react-bootstrap";
import CustomField from "./CustomField";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { MinusIcon, PlusIcon } from "@heroicons/react/outline";

const IncrementalFields = forwardRef(
  ({ fields, onChange, name, max = 10, hasDelete = false, ...props }:any, ref:any) => {
    const fieldsNamesObj = useMemo(() => {
      if (fields && Array.isArray(fields)) {
        return fields.reduce((acc, field) => {
          if (field?.name) {
            acc[field?.name] = null;
          }
          return acc;
        }, {});
      }
      return {};
    }, [fields]);

    const [rowsData, setRowsData] = useState([{ ...fieldsNamesObj }]);

    const addNewRow = useCallback(() => {
      if (rowsData.length < max) {
        setRowsData((prev) => [...prev, { ...fieldsNamesObj }]);
      }
    }, [max, rowsData, fieldsNamesObj]);

    useEffect(() => {
      if (onChange) {
        onChange({ target: { name: name, value: rowsData } });
      }
    }, [rowsData]);

    const handleChange = useCallback(
      (rowId, evt) => {
        if (rowsData.length > rowId) {
          rowsData[rowId][evt.target.name] = evt.target.value;
          setRowsData([...rowsData]);
        }
      },
      [rowsData]
    );

    const deleteRow = useCallback(
      (index) => {
        if (rowsData.length - 1 > index) {
          console.log("rowsData ==> ", rowsData, index);
          rowsData.splice(index, 1);
          console.log("after delete rowsData ==> ", rowsData, index);
          setRowsData([...rowsData]);
        }
      },
      [rowsData]
    );

    return (
      <Card className="p-3">
        <Stack gap={3}>
          {rowsData.map((rowData, rowIdx) => {
            const isLastRow = rowIdx === rowsData.length - 1;
            const showAdd = isLastRow && rowsData.length !== max;
            return (
              <Row
                className={`${isLastRow ? "" : "border-bottom pb-4"} px-3 d-flex align-item-center justify-content-center`}
                key={`meta_row_${rowIdx}`}
              >
                <Col lg={11} md={9} sm={12}>
                  <Row>
                    {fields?.map((field, fieldIdx) => (
                      <Col md={6} lg={4}>
                        <CustomField
                          ref={(ele) => ref?.current?.push(ele)}
                          {...field}
                          key={`meta_field_${rowIdx}${fieldIdx}_${field.name}`}
                          onChange={(evt) => handleChange(rowIdx, evt)}
                          value={rowData[field.name]}
                        />
                      </Col>
                    ))}
                  </Row>
                </Col>
                <Col lg={1} md={3} sm={12}>
                  <Stack
                    className="flex-row mt-3 justify-content-center"
                    gap={3}
                  >
                    {showAdd && (
                      <Button
                        variant="primary"
                        title="Add New"
                        onClick={addNewRow}
                      >
                        <PlusIcon className="d-flex align-items-center" width="16" />
                      </Button>
                    )}
                    {rowsData.length > 1 && hasDelete && (
                      <Button
                        variant="secondary"
                        title="Delete"
                        onClick={() => deleteRow(rowIdx)}
                      >
                        <MinusIcon className="d-flex align-items-center" width="16" />
                      </Button>
                    )}
                  </Stack>
                </Col>
              </Row>
            );
          })}
        </Stack>
      </Card>
    );
  }
);

export default IncrementalFields;