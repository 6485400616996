const Summary = () => {
    return (
        <p
            className="text-muted text-center"
            style={{ fontSize: 14, fontWeight: 600 }}
          >
            Welcome to Facet Dashboard Summary Page
          </p>
      );
};
export default Summary;
