import { Button, Modal as BModal } from "react-bootstrap";
import styles from "./index.module.scss";

const Modal = (props) => {
  return (
    <>
      <BModal
        show={true}
        onHide={props.handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${styles.customDialog} ${props.className || ""}`}
        scrollable
        size={props.size}
      >
        <BModal.Header closeButton className={styles.modalHeader}>
          <BModal.Title id="contained-modal-title-vcenter">
            {props?.config?.title || "Modal"}
          </BModal.Title>
        </BModal.Header>
        <BModal.Body>{props?.config?.body || "No data found!"}</BModal.Body>
        {props?.config?.footer && (
          <BModal.Footer>
            <Button variant="secondary" onClick={props.handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={props.handleClose}>
              Save Changes
            </Button>
          </BModal.Footer>
        )}
      </BModal>
    </>
  );
};

export default Modal;
