import { useReducer, useState, useEffect, useMemo } from "react";
import { getsafissd } from "../../services/controltable";
import { sortFn } from "../../components/CustomTable";
import moment from "moment";

export enum SAFI_ACTIONS {
  INIT_RULES = "INIT_RULES",
  ADD_RULE = "ADD_RULE",
  UPDATE_RULE = "UPDATE_RULE",
  DELETE_RULE = "DELETE_RULE",
  FETCH_RULES = "FETCH_RULES",
}

export default function useSSIDData() {
  const [apiData, setApiData] = useState({});
  const [loading, setLoading] = useState(false);

  async function fetchData(year) {
    setLoading(true);
    const data = await getsafissd(year);
    if (data.resData?.data) {
      setApiData(data.resData?.data[0] || []);
      console.log("fecthed ==> ", data.resData?.data[0]);
    }
    setLoading(false);
  }

  const getProperRule = (data) => {
    return {
     allowedCareers: data.allowedCareers,
     allowedRegTypes: data.allowedRegTypes,
    //  allowSSD: data.allowSSD,
     allowSummerCourses: data.allowSummerCourses,
     excludedClassficationIds: data.excludedClassficationIds,
     excludedCollegeIds: data.excludedCollegeIds,
     excludeMajors: data.excludeMajors
    }
  }

  const {
    tableData,
    dicts,
    created_at,
    updated_at,
    updated_by,
    enable_safi_control,
    enable_ssd,
    enable_summer_ssd,
    enable_summer_ssd_md,
    description,
    control_value,
    control_name,
  } = useMemo(() => {
    const objConst = {
      created_at: (apiData as any).created_at,
      updated_at: (apiData as any).updated_at,
      updated_by: (apiData as any).updated_by,
      enable_safi_control:  (apiData as any).enable_safi_control,
      enable_ssd:  (apiData as any).enable_ssd,
      enable_summer_ssd:  (apiData as any).enable_summer_ssd,
      enable_summer_ssd_md:  (apiData as any).enable_summer_ssd_md,
      control_value: (apiData as any).control_value,
      control_name: (apiData as any).control_name,
      description: (apiData as any).description,
    };
    const tableData = (apiData as any).dictionary?.reduce((acc, dict) => {
      const rule = dict?.rules[0];
      acc.push({ ...rule, effectiveDate: dict.effectiveDate, ...objConst });
      return acc;
    }, []).sort((a, b) => {
        return sortFn(moment(a.effectiveDate).unix(), moment(b.effectiveDate).unix());
    });
    return { tableData, ...objConst, dicts: (apiData as any).dictionary };
  }, [apiData]);

  const reducer = (state, action) => {
    console.log("action ==> ", action)
    switch (action.type) {
      case SAFI_ACTIONS.ADD_RULE: {
        const tableData = state.tableData || []
        const dicts = state.dicts || []
        const newData = action.payload
        const newDict = {
            effectiveDate: action.payload.effectiveDate,
            rules: [{
                ...getProperRule(action.payload)
            }]
        }
        tableData?.push(newData)
        dicts?.push(newDict)
        setTimeout(() => {
          action.callBack(tableData, dicts)
        }, 1500)
        return ({ ...state, tableData, dicts })
      }
      case SAFI_ACTIONS.UPDATE_RULE: {
        tableData[action.payload.index] = action.payload.data
        const dicts = state.dicts
        dicts[action.payload.index].effectiveDate = action.payload.data.effectiveDate
        dicts[action.payload.index].rules[0] = getProperRule(action.payload.data)
        return { ...state, tableData: [...tableData], dicts }
      }
      case SAFI_ACTIONS.DELETE_RULE:
        break;
      case SAFI_ACTIONS.FETCH_RULES: {
          fetchData(action.payload.value)
          return { ...state }
      }
      case SAFI_ACTIONS.INIT_RULES: 
        return { ...state, ...action.payload }
      default:
        return { ...state };
    }
  };

  

  const [state, dispatch] = useReducer(reducer, {
    tableData,
    dicts,
  });

  useEffect(() => {
    dispatch({ type:SAFI_ACTIONS.INIT_RULES, payload: {tableData, dicts} })
  }, [tableData, dicts, dispatch])

  return {
    ...state,
    dispatch,
    created_at,
    updated_at,
    updated_by,
    enable_safi_control,
    enable_ssd,
    enable_summer_ssd,
    enable_summer_ssd_md,
    description,
    control_value,
    control_name,
    loading,
  };
}
