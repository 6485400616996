import {
  ReactNode,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { Col, Row, Spinner, Stack } from "react-bootstrap";
import { PaginationControl } from "react-bootstrap-pagination-control";
import Table, { TableProps } from "react-bootstrap/Table";
import TableActions, { TableActionsProps } from "./TableActions";
import { saveAs } from "file-saver";
import Excel from "exceljs";
import ColumnResizer from "column-resizer";
import moment from "moment";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/solid";

export interface CustomColumn {
  name: string;
  label: React.ReactNode;
  width?: number | string;
  showInExcel?: boolean;
  cellClass?: string;
  headerClass?: string;
  styles?: React.CSSProperties;
  resizable?: boolean;
  renderHeader?: (label: React.ReactNode, col: CustomColumn) => React.ReactNode;
  renderRow?: (value: any, row: CustomRow, index: number) => React.ReactNode;
  type?: ColType;
  sortable?: boolean;
}

export interface CustomRow {
  [name: string]: unknown;
}

export interface CustomTableProps extends TableProps {
  columns: CustomColumn[];
  rows: CustomRow[];
  showIndex?: boolean;
  tableName?: string;
  selectable?: boolean;
  expandable?: boolean;
  headerRowClass?: string;
  rowClass?: string;
  responsive?: boolean;
  rowsPerPage?: number;
  editable?: boolean;
  pagination?: "default" | false | ReactNode;
  title?: string;
  hasTableActions?: boolean;
  tableActionProps?: TableActionsProps;
  bordered?: boolean;
  striped?: boolean;
  className?: string;
  parentClass?: string;
  isLoading?: boolean;
  lineHeight?: string;
  resizable?: boolean;
  resizeOptions?: any;
  noRowsText?:string;
}

async function CreateExcel(
  name: string,
  columns: CustomColumn[],
  rows: CustomRow[]
) {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(name);
  worksheet.columns = columns
    .filter((col) => col.showInExcel)
    .map((col) => ({ header: col.label as string, key: col.name as string }));
  worksheet.addRows(rows);
  await workbook.xlsx.writeBuffer().then((data) => {
    const blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    saveAs(blob, `${name}.xlsx`);
  });
}

enum SortType {
  ASC,
  DESC,
}

type ColType = "number" | "string" | "object" | "date";

export const sortFn = (a, b) => {
  if (a > b) return 1;
  if (a < b) return -1;
  return 0;
};

const getSortedData = (
  data: CustomRow[],
  colName: string,
  sortType: SortType = SortType.ASC,
  colType?: ColType
) => {
  if (colType === "number") {
    return data.sort((a, b) => {
      if (sortType === SortType.ASC)
        return sortFn(Number(a[colName]), Number(b[colName]));
      return sortFn(Number(b[colName]), Number(a[colName]));
    });
  } else if (colType === "date") {
    return data.sort((a, b) => {
      if (sortType === SortType.ASC)
        return sortFn(moment(a[colName]).unix(), moment(b[colName]).unix());
      return sortFn(moment(b[colName]).unix(), moment(a[colName]).unix());
    });
  } else if (colType === "object") {
    const [col, key] = colName.split(".");
    return data.sort((a, b) => {
      if (sortType === SortType.ASC) return sortFn(a[col][key], b[col][key]);
      return sortFn(b[col][key], a[col][key]);
    });
  }
  return data.sort((a, b) => {
    if (sortType === SortType.ASC)
      return sortFn(String(a[colName]), String(b[colName]));
    return sortFn(String(b[colName]), String(a[colName]));
  });
};

const CustomTable = forwardRef<HTMLTableElement, any>(
  (
    {
      columns,
      rows,
      showIndex = false,
      tableName = "custom_table",
      headerRowClass = "",
      rowClass = "",
      responsive = true,
      rowsPerPage = 10,
      pagination = false,
      editable = false,
      title = "Table title",
      hasTableActions,
      tableActionProps = {},
      parentClass = "",
      noRowsText = "",
      isLoading = false,
      lineHeight = "12px",
      resizable = true,
      resizeOptions = {},
      pageRef,
      ...rest
    },
    ref
  ) => {
    const [page, setPage] = useState(1);
    const [sortedCols, setSortedCols] = useState<{ [name: string]: SortType }>(
      {}
    );

    useImperativeHandle(
      pageRef,
      () => ({
        setPage: setPage,
      }),
      []
    );

    const [search, setSearch] = useState("");
    const [resize, setResize] = useState<any>();
    let newRows = useMemo(() => {
      const searchedRows = rows.filter((row) =>
        JSON.stringify(Object.values(row)).includes(search)
      );
      return searchedRows.slice((page - 1) * rowsPerPage, rowsPerPage * page);
    }, [page, rows, rowsPerPage, search]);
    const { onSearch, onDownload, onAddRow, ...actionProps } = tableActionProps;

    const handleColHeaderClick = (col) => {
      if (col.sortable) {
        const sortType =
          sortedCols[col.name] === SortType.ASC ? SortType.DESC : SortType.ASC;
        newRows = getSortedData(newRows, col.name, sortType, col.type);
        setSortedCols((prev) => ({ ...prev, [col.name]: sortType }));
      }
    };

    const handleSearch = (search: string) => {
      onSearch ? onSearch(search) : setSearch(search);
    };
    const handleDownload = () => {
      // add default download logic
      if (onDownload) onDownload();
      else {
        CreateExcel(`${title.replace(/ /g, "_")}_${Date.now()}`, columns, rows);
      }
    };
    const handleAddRow = () => {
      if (onAddRow) onAddRow();
    };

    const enableResize = () => {
      if (!resize) {
        const resizeFn = new ColumnResizer((ref as any).current, {currentWidths: columns.map(col => col.width || 150), ...resizeOptions});

        //@ts-ignore
        ref.current.className = ref.current?.className?.replace(
          "grip-padding",
          ""
        );

        setResize(resizeFn);
      } else {
        resize?.reset({currentWidths: columns.map(col => col.width || 150), ...resizeOptions});
      }
    };

    const disableResize = () => {
      if (!!resize) {
        resize?.reset({ disable: true });
      }
    };

    useEffect(() => {
      if ((ref as any)?.current && resizable) {
        enableResize();
      }
      return () => disableResize();
    }, [ref, resizable]);

    const commonClass = "d-flex justify-content-center align-items-center";
    return (
      <Stack className={parentClass} style={{ maxWidth: '85vw' }}>
        {(title || hasTableActions) && (
          <Row className="p-3">
            <Col
              sm={12}
              md={6}
              className={`h4 justify-content-lg-start ${commonClass}`}
            >
              {title}
            </Col>
            <Col
              sm={12}
              md={6}
              className={`justify-content-lg-end ${commonClass}`}
            >
              <TableActions
                hasAddRow
                hasDownload
                hasSearch
                onSearch={handleSearch}
                onDownload={handleDownload}
                onAddRow={handleAddRow}
                {...actionProps}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Stack>
            <Table responsive={responsive} {...rest} ref={ref}>
              <thead>
                <tr className={headerRowClass || ""}>
                  {showIndex && <th className="text-center">#</th>}
                  {columns.map((col) => (
                    <th
                      className={`${col?.headerClass || ""}`}
                      onClick={() => handleColHeaderClick(col)}
                      style={{
                        width: col.width || `${100 / columns.length}%`,
                        resize:
                          resizable &&
                          (col.resizable === undefined ||
                            col.resizable === true)
                            ? "horizontal"
                            : "none",
                        ...col.styles,
                      }}
                      key={col.name}
                      title={col.label}
                    >
                      {col.renderHeader
                        ? col.renderHeader(col.label, col)
                        : col.label}
                      {col.sortable && <span
                        className="m-1"
                        style={{ verticalAlign: "text-bottom" }}
                      >
                        {sortedCols[col.name] !== undefined ? (
                          sortedCols[col.name] === SortType.ASC ? (
                            <ArrowDownIcon width={14} height={14} />
                          ) : (
                            <ArrowUpIcon width={14} height={14} />
                          )
                        ) : null}
                      </span>}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className={`${newRows.length ? "" : "border"}`}>
                {newRows.length > 0 && !isLoading &&
                  newRows.map((row, index) => {
                    return (
                      <tr
                        key={`${tableName}_${index}`}
                        style={{ lineHeight: lineHeight }}
                        className={`${rowClass || ""}`}
                      >
                        {showIndex && (
                          <td
                            className={`${
                              row.isNewRow || row.modified
                                ? "bg-light-blue"
                                : ""
                            } text-center`}
                          >
                            {index + 1 + (page - 1) * rowsPerPage}
                          </td>
                        )}
                        {columns.map(
                          ({ name, width, cellClass, renderRow }) => {
                            return (
                              <td
                                key={`${tableName}_${name}_${index}`}
                                style={{
                                  width,
                                  lineHeight,
                                  verticalAlign: "middle",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                                className={`${cellClass || ""} ${
                                  row.isNewRow || row.modified
                                    ? "bg-light-blue"
                                    : ""
                                }`}
                                title={
                                  (typeof row[name] === "string"
                                    ? row[name]
                                    : "") as string
                                }
                              >
                                {renderRow
                                  ? renderRow(
                                      row[name],
                                      row,
                                      index + (page - 1) * rowsPerPage
                                    )
                                  : row[name]}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {isLoading && (
              <div className="border">
                <div className="d-flex justify-content-center m-2 align-items-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </div>
            )}
            {!isLoading && !newRows.length && (
              <div className="border">
                <div className="p-2 h6 text-black-50 d-flex justify-content-center m-2 align-items-center">
                {noRowsText ? noRowsText : 'No data to show table rows'}
                </div>
              </div>
            )}

            {pagination === "default" ? (
              <PaginationControl
                page={page}
                between={3}
                total={rows.length}
                limit={rowsPerPage}
                changePage={(page) => {
                  setPage(page);
                }}
                ellipsis={2}
              />
            ) : (
              pagination || null
            )}
          </Stack>
        </Row>
      </Stack>
    );
  }
);

export default CustomTable;
