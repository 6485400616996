import { generateQueryString } from "../Utilities";
import { http } from "../common/http";
const API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED = process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED;
// const API_URL_MESSAGEDETAILS_JSON = process.env.REACT_APP_API_URL_MESSAGEDETAILS_JSON;
const API_URL_MESSAGEDETAILS_XML =process.env.REACT_APP_API_URL_MESSAGEDETAILS_XML;
const API_URL_JOBDETAILS_JSON = process.env.REACT_APP_API_URL_JOBDETAILS_JSON;

const getToken = () => {
    return localStorage.getItem("token");
  // return sessionStorage.getItem("token");
};
const getJobs = async () => {
  const token = getToken();
  const response = await http.get(
    `${API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}jobs`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response?.resData?.data || [];
};

const advanceSearch = async (payload: any) => {
  const query = generateQueryString(payload);
  const token = getToken();
  const data = await http.get(
    `${process.env.REACT_APP_API_ADV_SEARCH}?${query}`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return data;
};

const searchTransactions = async (payload: any) => {
  const query = generateQueryString(payload);
  const token = getToken();
  const {
    resData: { data },
  } = await http.get(
    `${API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}searchTransactions?${query}`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return [...data];
};

const getTransactions = async () => {
  const token = getToken();
  const response = await http.get(
    `${API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}searchTransactions`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response?.resData?.data || [];
};

const getVugTransactions = async () => {
  const token = getToken();
  const response = await http.get(
    `${API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}vugs`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response?.resData?.data || [];
  // console.log("REsponse", response);
  // return Response.data;
};

const getStudentInitation = async () => {
  const token = getToken();
  const response = await http.get(
    `${API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}sis`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response?.resData?.data || [];
};

// const getVocadoMessage = async (id) => {
//   const response = await http.get(`${ API_URL_MESSAGEDETAILS_JSON}messages/${id}`);
//   return response?.resData?.data || [];
// };

const getVocadoMessageXml = async (id) => {
  const token = getToken();
  const response = await http.getXMLID(
    `${API_URL_MESSAGEDETAILS_XML}?messageId=${id}`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response;
};

// export const postSIFAdminForm = (body) => {
//   const token =getToken()
//   return fetch(`${API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}studentadmincreate`, {
//     method: 'POST',
//     headers : {
//       Authorization: `Bearer ${token}`
//     },
//     body: JSON.stringify(body)
//   }).then(res => res.json())
// .then(res => res)
//  }
const getJobDetails = async (id) => {
  const token = getToken();
  const response = await http.get(
    `${API_URL_JOBDETAILS_JSON}jobdetails/${id}`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
  return response?.resData?.data || [];
};

// export const searchStudent = (id) => {
//   const token = getToken();
//   return fetch(`https://api.dev.facet.it.ucla.edu/transactions/student/${id}`,  {
//     headers: {
//       Authorization: `Bearer ${token}`
//     },
//   }).then(res => res.json()).then(res => res)
// }

const DashboardService = {
  getJobs,
  getTransactions,
  // getVocadoMessage,
  getVocadoMessageXml,
  getStudentInitation,
  getVugTransactions,
  getJobDetails,
  searchTransactions,
  advanceSearch,
};

export default DashboardService;
