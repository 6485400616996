import Tabs from "react-bootstrap/Tabs";
import { Button, Tab } from "react-bootstrap";
import { useEffect, useState } from "react";
import { RootState } from "../../reducers";
import { useSelector } from "react-redux";
import Jobs from "./Jobs";
import Transactions from "./Transactions";
import StudentInitated from "./StudentIntiated";
import VugTransactions from "./VugTransactions";
import styles from "./index.module.scss";
import "./index.module.scss";
import ExcelDownload from "../../components/Common/ExcelDownload";
import { Link } from "react-router-dom";
import Summary from "./Summary";
const Dashboard = () => {
  const dashboard = useSelector((state: RootState) => state.dashboard);
  const {jobs, transactions, studentintiation, vugTransactions } = dashboard;
  console.log('jobs', jobs)
  const [excelData, setExcelData] = useState("summary");
  const [map, setMap] = useState({});
  useEffect(() => {
    // console.log(dashboard);
  }, [dashboard]);

  useEffect(() => {
    if (
      vugTransactions.length ||
      studentintiation.length ||
      transactions.length ||
      jobs.length
    )
      setMap({
        jobs: {
          data: jobs,
          fileName: "Jobs",
          isVisible: true,
        },
        transactions: {
          data: transactions,
          fileName: "Transactions",
          isVisible: true,
        },
        student: {
          data: studentintiation,
          fileName: "Student Initiation",
          isVisible: true,
        },
        vugtrans: {
          data: vugTransactions,
          fileName: "Vug Transactions",
          isVisible: true,
        },
      });
  }, [vugTransactions, transactions, studentintiation, jobs]);

  const saveFilteredData = (data) => {
    setMap({
      ...map,
      [excelData]: {
        ...map[excelData],
        data,
      },
    });
  };
  console.log('map', map)
  const [data, setData] = useState([]);

  return (
    <>
      <div className={styles.outer_wrapper}>
        <Tabs
          defaultActiveKey="summary"
          id="uncontrolled-tab-dashboard"
          className="mb-3"
          onSelect={(key) => setExcelData(key)}
          activeKey={excelData}
        >
          <Tab eventKey="summary" title="SUMMARY">
            <Summary  />
          </Tab>
           <Tab eventKey="jobs" title="JOBS">
            {Boolean(jobs?.length > 1000) ? (
              <p className={styles.page_text}>
                The results are limited the first 1000 records, Please revise
                your search ceriteria to narrow your search, if needed.
              </p>
            ) : null}

            {excelData === 'jobs' && <Jobs passFilteredData={saveFilteredData} gridData={jobs ?? []} />}
          </Tab>
          <Tab eventKey="transactions" title="TRANSACTIONS">
            {Boolean(transactions?.length > 1000) ? (
              <p className={styles.page_text}>
                The results are limited the first 1000 records, Please revise
                your search ceriteria to narrow your search, if needed.
              </p>
            ) : null}
            {excelData === 'transactions' && <Transactions
              gridData={transactions ?? []}
              passFilteredData={saveFilteredData}
            />}
          </Tab>
          <Tab eventKey="student" title="STUDENT INTIATED">
            {Boolean(studentintiation?.length > 1000) ? (
              <p className={styles.page_text}>
                The results are limited the first 1000 records, Please revise
                your search ceriteria to narrow your search, if needed.
              </p>
            ) : null}
            {excelData === 'student' && <StudentInitated
              passFilteredData={saveFilteredData}
              gridData={studentintiation ?? []}
            />}
          </Tab>
          <Tab eventKey="vugtrans" title="VUG TRANSACTIONS">
            {console.log(vugTransactions, excelData, "test vug")}
            {Boolean(vugTransactions?.length > 1000) ? (
              <p className={styles.page_text}>
                The results are limited the first 1000 records, Please revise
                your search ceriteria to narrow your search, if needed.
              </p>
            ) : null}
            {excelData === 'vugtrans' && <VugTransactions
              passFilteredData={saveFilteredData}
              gridData={vugTransactions ?? []}
            />}
          </Tab>
        </Tabs>
        {/* { <div className={styles.pos_adv_search}>
          <Link to='/adv-search'>Adv Search</Link>
        </div> } */}
        <div className={styles.pos_excel}>
          <ExcelDownload
            isVisible={
              Object.keys(map).length && excelData
                ? map[excelData].isVisible
                : false
            }
            data={
              Object.keys(map).length && excelData
                ? map[excelData]?.data ?? []
                : []
            }
            filename={
              Object.keys(map).length && excelData
                ? map[excelData].fileName
                : ""
            }
          />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
