import { useEffect, useMemo } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import MainLayout from "../../layouts";
import { fetchToken, fetchdecodeToken } from "../../services/tokenService";
import { useContext } from "react";
import { AppContext } from "../../common/AppProvider";

import TopNav from "../TopNav";
import { Spinner } from "react-bootstrap";
import moment from "moment";
// import { logoutIfTokenFails } from "../../actions/dashboard";

const ProtectedRoute = ({ route: Component, ...props }) => {
  const {
    update,
    data: { login, expiry },
    availablePages,
  } = useContext(AppContext);
  const history = useHistory();
  const url = process.env.REACT_APP_API_SHIBBOLETH_URL;
  // const url = "https://up-users-non-shib.auth.us-west-2.amazoncognito.com/login?client_id=65ee6596ba267mn74uuchvdbja&response_type=code&scope=email+openid+phone&redirect_uri=http%3A%2F%2Flocalhost%3A3000";
  // const url = "https://facet-userpool.auth.us-west-2.amazoncognito.com/login?client_id=3c501ijsel3702ima8ce8rhppj&response_type=code&scope=api.dev.facet.it.ucla.edu%2Ftransactions&redirect_uri=http%3A%2F%2Flocalhost%3A3000";

  const location = useLocation();

  useEffect(() => {
    const hasAccess =
      availablePages.length > 0 && availablePages.includes(location.pathname);
    // console.log("location ==> ", location, availablePages);
    if (login && !hasAccess) {
      history.push("/error");
    }
  }, [availablePages, location, login]);

  useEffect(() => {
    async function checkAuthentication() {
       const isLoggedIn = localStorage.getItem("isLoggedIn") === "true" && login?.exp && moment().unix() < login.exp;
      // const isLoggedIn = sessionStorage.getItem("isLoggedIn") === "true";
      const search = window.location.search;
      if (search.length === 0 && !isLoggedIn) {
        window.location.href = url;
      } else {
          if (search.includes("error")) {
          localStorage.setItem("isLoggedIn", "false");
          // sessionStorage.setItem("isLoggedIn", "false");
          history.push(`/error${search}`);
          //  history.push("/")
        } else if (search.includes("code=")) {
          const [_, code] = search.split("code=");
          const response = await fetchToken(code);
          const tokenData = fetchdecodeToken(response.id_token);
          // console.log(tokenData, "login data Response");
          if (tokenData) {
            update({ type: "UPDATE_LOGIN", payload: tokenData });
          }
            localStorage.setItem("isLoggedIn", "true");
          // sessionStorage.setItem("isLoggedIn", "true");
        }
      }
    }
    checkAuthentication();
  }, [history]);

  return (
    <MainLayout>
      {login ? (
        <Component />
      ) : (
        <div className="container d-flex justify-content-center align-items-center">
        <Spinner color="primary" animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
        </div>
      )}
    </MainLayout>
  );
};
export default ProtectedRoute;
