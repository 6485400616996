import { encode } from "querystring";
import { http } from "../common/http";
import jwt_decode from "jwt-decode";

export const refetchToken = async () => {
       const refreshToken = localStorage.getItem("refresh-token");
    // const refreshToken = sessionStorage.getItem("refresh-token");
  try {
    if (!refreshToken) {
      throw new Error("No Refresh token available");
    }
    const body = {
      grant_type: "refresh_token",
      client_id: process.env.REACT_APP_API_CLIENT_ID,
      client_secret: process.env.REACT_APP_API_CLIENT_SECERET,
      refresh_token: refreshToken
    };
    const params = new URLSearchParams();
    params.append("grant_type", body["grant_type"]);
    params.append("client_id", body["client_id"]);
    params.append("client_secret", body["client_secret"]);
    params.append("refresh_token", body["refresh_token"]);

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    const data = await http.post(
      process.env.REACT_APP_API_FACETUSERPOOL_URL,
      headers,
      params
    );
    if (!data || data.error || data.status === 401) {
      throw new Error("Failed to fetch token");
    }
    if (data.access_token) {
        localStorage.setItem("token", data.access_token);
      // sessionStorage.setItem("token", data.access_token);
    }
    return data;
  } catch (err) {
    console.error("Error occured in refetch token:", err);
    // sessionStorage.clear();
    localStorage.clear();
    window.location.href = process.env.REACT_APP_API_SHIBBOLETH_URL;
  }
};

export const fetchToken = async (code) => {
  const body = {
    grant_type: "authorization_code",
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECERET,
    code,
    redirect_uri: process.env.REACT_APP_API_REDIRECT_URI
  };
  const params = new URLSearchParams();
  params.append("grant_type", body["grant_type"]);
  params.append("client_id", body["client_id"]);
  params.append("client_secret", body["client_secret"]);
  params.append("code", body["code"]);
  params.append("redirect_uri", body["redirect_uri"]);
  // params.append('scope', body['scope'])

  const headers = {
    "Content-Type": "application/x-www-form-urlencoded",
  };
  const data = await http.post(
    process.env.REACT_APP_API_FACETUSERPOOL_URL,
    headers,
    params
  );
  if (data.access_token) {
    localStorage.setItem("token", data.access_token);
    localStorage.setItem("refresh-token", data.refresh_token);
    localStorage.setItem("id-token", data.id_token);
    // sessionStorage.setItem("token", data.access_token);
    // sessionStorage.setItem("refresh-token", data.refresh_token);
    // sessionStorage.setItem("id-token", data.id_token);
  }
  return data;
  // return await http.post('https://up-users-non-shib.auth.us-west-2.amazoncognito.com/oauth2/token',headers,params)
};
export const fetchdecodeToken = (token = null) => {
  let encodedToken = token;
  if (!encodedToken) {
       encodedToken = localStorage.getItem("id-token");
    // encodedToken = sessionStorage.getItem("id-token");
  }
  const decoded = jwt_decode(encodedToken);
  console.log(decoded);
  return decoded;
};
