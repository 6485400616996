import React from "react";
import { Spinner } from "./loader";

const PageLoadepstyle = {
  width: "48px",
  height: "40%",
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%,-50%)",
};

/**
 * Page Loading spinner
 */
export const PageLoader = ({ isLoading = false, style = {} }) => (
  isLoading && <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      background: "#000",
      width: "100vw",
      height: "100vh",
      opacity: 0.4,
      ...style,
    }}
  >
    <Spinner color={"#5e94ff"} style={PageLoadepstyle} />
  </div>
);

/**
 * 404 component
 */
export const NotFound = () => (
  <div className="flex h-screen justify-center items-center">
    <h1 className="m-5">404 Not Found!</h1>
  </div>
);
