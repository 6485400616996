import { Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../common/constants";
import styles from "./index.module.scss";
import { useContext, useEffect } from "react";
import { AppContext } from "../../common/AppProvider";

const TopNav = () => {
  const {update, data } = useContext(AppContext);

  const history = useHistory();

  //  const getToken = () => {
  //   return localStorage.getItem("token");
  // };

  const logout = async () => {
    update({ type: "CLEAR_LOGIN" });
    localStorage.clear()
     window.location.reload();
  };

  useEffect(() => {
    // getToken();
  });

  // console.log(data.login, "login data");

  return (
    <nav className={styles.top_nav}>
      <Container>
        <div className={styles.top_nav__wrapper}>
          <Link to={ROUTES.APP_ROOT} className={styles.top_nav__link}>
            Home Page
          </Link>

          <ul>
            <li>
              <Link to={ROUTES.APP_ROOT}>
                {data?.login && data?.login["custom:displayName"]
                  ? data?.login["custom:displayName"]
                  : "UNKNOWN"}
              </Link>
            </li>
            <li>|</li>
            <li>
              <Link to={ROUTES.APP_ROOT}>Need Help?</Link>
            </li>
            <li>|</li>
            <li>
              <Link to={ROUTES.APP_ROOT}>Settings</Link>
            </li>
            <li>|</li>
            <li>
              <Link to={"#"} onClick={logout}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </Container>
    </nav>
  );
};

export default TopNav;
