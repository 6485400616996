import { Link } from "react-router-dom";
import { ROUTES } from "../../common/constants";
import styles from "./index.module.scss";
import { useContext } from "react";
import { AppContext } from "../../common/AppProvider";

const list = [
  {
    name: "DASHBOARD",
    link: ROUTES.DASHBOARD,
  },
  {
    name: "ADVANCED SEARCH",
    link: ROUTES.ADV_SEARCH,
  },
  {
    name: "ADMIN",
    link: ROUTES.ADMIN_FORMS,
  },
  {
    name: "FUNCTIONAL ADMIN",
    link: ROUTES.FUNCTIONAL_ADMIN,
  },
  {
    name: "CONTROL TABLE",
    link: ROUTES.CLASSES,
  },
  {
    name: "MONITORING DASHBOARDS",
    link: ROUTES.MONITORING_DASHBOARDS,
  },
  {
    name: "COVERAGE",
    link: ROUTES.COVERAGE,
  },
  // {
  //   name: "STAFF",
  //   link: ROUTES.APP_ROOT,
  // },
  // {
  //   name: "FINANCE AND JOBS",
  //   link: ROUTES.APP_ROOT,
  // },
  // {
  //   name: "CAMPUS LIFE",
  //   link: ROUTES.APP_ROOT,
  // },
  // {
  //   name: "OTHER STUDENT SERVICES",
  //   link: ROUTES.APP_ROOT,
  // },
];

const Menu = () => {
  const { availablePages } = useContext(AppContext);
  return (
    <div className={styles.menu}>
      <ul>
        {list
          .filter((item) => availablePages.includes(item.link))
          .map((item, k) => (
            <li key={k}>
              <Link to={item.link}>{item.name}</Link>
              <span> | </span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Menu;
