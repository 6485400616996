import CustomTabs from "../../components/CustomTabs";
import { SafiSSd } from "./SafiSsdControls";
import { UnexSSD } from "./UnexControls";
import ManualFileUpload from "./ManualFileUpload";
import { SafiBar } from "./SafiBarFlagsTable";
import { AuditData } from "./AuditDataTable";

const tabs = [
  {
    title: "Manual File Upload",
    eventKey: "file uploads",
    Component: ManualFileUpload,
  },
  {title: 'SAFI SSD Controls', eventKey: 'safi ssd',Component: SafiSSd},
  {title: 'UNEX SAFI/SSD Controls', eventKey: 'unex ssd',Component: UnexSSD},
  {title: 'BAR to SFP Fund Code Mapping', eventKey: 'Safi Bar table',Component: SafiBar},
  {title: 'Audit Data Table', eventKey: 'audit table',Component: AuditData},
];

const FunctionalAdmin = ({}) => {
  return <CustomTabs defaultActiveKey="file uploads" tabs={tabs} />;
};
export default FunctionalAdmin;
