
import { http } from "../common/http";

const getToken = () => {
     return localStorage.getItem("token");
  // return sessionStorage.getItem("token");
};

// export const getControlledFlagTable = async () => {
//   const token = getToken();
//   let response;
//   try {
//     response = await http.get(
//       `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}controlflags`,
//       {
//         Authorization: `Bearer ${token}`,
//       }
//     );
//   } catch (err) {
//     console.error(err);
//   }
//   return response;
// };

export const getControlledFlagTableUID = async (id) => {
  const token = getToken();
  let response;
  try {
    response = await http.get(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}controlflags/${id}`,
      {
        Authorization: `Bearer ${token}`,
      }
    );
  } catch (err) {
    console.error(err);
  }
  return response;
};

export const createControlledFlagTable = async (body) => {
  const token = getToken();
  try {
    return await http.post(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}controlflags/create`,
      {
        Authorization: `Bearer ${token}`,
      },
      body
    );
  } catch (err) {
    console.error(err);
  }
};

export const updateControlledFlagTable = async (body) => {
  const token = getToken();
  try {
    return await http.put(
      `${process.env.REACT_APP_API_URL_JOBS_TRANSACTIONS_STUDENTINITIATED}controlflags/update`,
      {
        Authorization: `Bearer ${token}`,
      },
      JSON.stringify(body)
    );
  } catch (err) {
    console.error(err);
  }
};

