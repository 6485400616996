import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import CustomTable, { CustomColumn } from "../../components/CustomTable";
import styles from "./index.module.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Stack,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { AppContext } from "../../common/AppProvider";
import { getAuditData } from "../../services/safibarflagsTable";
import Modal from "../../components/Common/Modal";

const TOAST_CONFIG = {
  SUCCESS: {
    title: "Success",
    backgroundColor: "#274f82",
    color: "white",
  },
  FAILED: {
    title: "Failed",
    backgroundColor: "#274f82",
    color: "white",
    msgColor: "red",
  },
};

export const AuditData = () => {
  const { data } = useContext(AppContext);

  const columns: Array<CustomColumn> = [
    {
      name: "username",
      label: "User",
      cellClass: `${styles.updateddate_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 75,
    },
    {
      name: "table_name",
      label: "Table Name",
      cellClass: `${styles.uid1_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 200,
    },
    // {
    //   name: "event_id",
    //   label: "Event ID",
    //   cellClass: `${styles.updateddate_col}`,
    //   showInExcel: true,
    //   headerClass: `text-nowrap`,
    //   width: 230,
    // },
    {
      name: "event_id_new",
      label: "After",
      cellClass: `${styles.academicyear_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      // width: 120,
      // renderRow: (value, row, index) => {
      //   return (
      //     <span
      //       onClick={() => {
      //         if(value.length > 25){
      //           setShowAuditModal(value)
      //           console.log(JSON.stringify(value) )
      //           console.log("JSON", JSON.parse(JSON.stringify(value, null,2)))
      //         }else{
      //           setShowAuditModal(null)
      //         }
      //       }}
      //       style={{
      //         display: "inline-block",
      //         whiteSpace: "nowrap",
      //         width: "150px",
      //         textOverflow: "ellipsis",
      //         overflow: "hidden",
      //       }}
      //     >
      //       {value}
      //     </span>
      //   );
      // },
      renderRow: (value, row, index) => {
        return row.new_image ? (
          <a
            href="#"
            onClick={() => {
              if (row.new_image) {
                setShowAuditModal({
                  title: `New Image details`,
                  data: row.new_image,
                });
              }
            }}
            // style={{
            //   display: "inline-block",
            //   whiteSpace: "nowrap",
            //   width: "120px",
            //   textOverflow: "ellipsis",
            //   overflow: "hidden",
            // }}
          >
            {value}
          </a>
        ) : (
          <>{value}</>
        );
      },
    },
    {
      name: "event_id_old",
      label: "Before",
      cellClass: `${styles.flagvalue_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      renderRow: (value, row, index) => {
        return row.old_image && Object.keys(row.old_image).length ? (
          <a
            href="#"
            onClick={() => {
              if (row.old_image) {
                setShowAuditOldModal({
                  title: `Old Image details`,
                  data: row.old_image,
                });
              }
            }}
            // style={{
            //   display: "inline-block",
            //   whiteSpace: "nowrap",
            //   width: "120px",
            //   textOverflow: "ellipsis",
            //   overflow: "hidden",
            // }}
          >
            {value}
          </a>
        ) : (
          <>{value}</>
        );
      },
      // width: 40,
      // renderRow: (value, row, index) => {
      //   return (
      //     <span
      //       onClick={() => {
      //         if(value.length > 25){
      //           setShowAuditOldModal(value)
      //           console.log(JSON.stringify(value) )
      //           console.log("JSON", JSON.parse(JSON.stringify(value, null,2)))
      //         }else{
      //           setShowAuditOldModal(null)
      //         }
      //       }}
      //       style={{
      //         display: "inline-block",
      //         whiteSpace: "nowrap",
      //         width: "150px",
      //         textOverflow: "ellipsis",
      //         overflow: "hidden",
      //       }}
      //     >
      //       {value}
      //     </span>
      //   );
      // },
    },
    {
      name: "operation",
      label: "Operation",
      cellClass: `${styles.updateddate_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      width: 75,
    },
    // {
    //   name: "updated_by",
    //   label: "Updated By",
    //   cellClass: `${styles.updateddate_col}`,
    //   showInExcel: true,
    //   headerClass: `text-nowrap`,
    //   width: 75,
    // },
    {
      name: "timestamp",
      label: "Last Updated",
      cellClass: `${styles.updateddate_col}`,
      showInExcel: true,
      headerClass: `text-nowrap`,
      // width: 100,
    },
  ];

  const ref = useRef();
  const [toast, setToast] = useState({
    mode: "",
    msg: "",
  });
  const [auditData, setAuditData] = useState([]);
  const [showAuditModal, setShowAuditModal] = useState(null);
  const [showAuditOldModal, setShowAuditOldModal] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchSafiBarFundCodeTable() {
    setIsLoading(true);
    const data = await getAuditData();
    if (data.resData?.data) {
      setAuditData(data.resData.data);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetchSafiBarFundCodeTable();
    const data = [];
  }, []);

  return (
    <div className="safi-bar-table relative">
      <Col className="relative">
        <Row>
          <CustomTable
            parentClass={styles.fullTableWidth as any}
            isLoading={isLoading}
            rows={auditData || []}
            columns={columns}
            bordered
            ref={ref}
            rowsPerPage={process.env.REACT_APP_ROWS_PAGE}
            rowClass="test"
            responsive
            hasTableActions={true}
            tableActionProps={{
              hasAddRow: false,
              hasSearch: true,
              hasDownload: false,
            }}
            striped
            title={null}
            style={{ tableLayout: "fixed" }}
            pagination="default"
          />
        </Row>
      </Col>
      {showAuditModal && (
        <Modal
          handleClose={() => setShowAuditModal(false)}
          config={{
            footer: false,
            title: showAuditModal.title,
            body: (
             <div className={styles.Modal_Body}>
             {/* <div className={styles.json_body}>{showAuditModal}</div> */}
             <pre>{JSON.stringify(showAuditModal.data, undefined, 2)}</pre>
            </div>
            )    
          }}
        />
      )}
      {showAuditOldModal && (
        <Modal
          handleClose={() => setShowAuditOldModal(false)}
          config={{
            footer: false,
            title: showAuditOldModal.title,
            body: (
             <div className={styles.Modal_Body}>
            {/* <div className={styles.json_body}>{showAuditOldModal}</div> */}
             <pre>{JSON.stringify(showAuditOldModal.data, undefined, 2)}</pre>
            </div>
            )    
          }}
        />
      )}
      <div>
        <ToastContainer position="top-center">
          <Toast show={!!toast?.mode} onClose={() => setToast(null)}>
            <Toast.Header style={{ ...TOAST_CONFIG[toast?.mode] }}>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">
                {TOAST_CONFIG?.[toast?.mode]?.title || "TITLE"}
              </strong>
              {/* <strong className="me-auto">Failed</strong> */}
              <small></small>
            </Toast.Header>
            <Toast.Body
              style={{ color: TOAST_CONFIG?.[toast?.mode]?.msgColor }}
            >
              {toast?.msg}
            </Toast.Body>
          </Toast>
        </ToastContainer>
      </div>
    </div>
  );
};
