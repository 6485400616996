// import {
//     ArrowDownTrayIcon,
//     MagnifyingGlassIcon,
//     PlusIcon,
//   } from "@heroicons/react/solid";
  import { DownloadIcon, PlusIcon, SearchIcon } from "@heroicons/react/outline";
import React, { useCallback, useState } from "react";
  import { Button, Col, Form, InputGroup, Row, Stack } from "react-bootstrap";
  
  export interface TableActionsProps {
    hasSearch: boolean;
    hasAddRow: boolean;
    hasDownload: boolean;
    onAddRow?: () => void;
    onDownload?: () => void;
    onSearch?: (search: String) => void;
    children?: React.ReactElement
  }
  
  export default function TableActions({
    hasAddRow,
    hasSearch,
    hasDownload,
    onSearch,
    onDownload,
    onAddRow,
    children,
  }: TableActionsProps) {
    const [search, setSearch] = useState("");
  
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    };
  
    const handleSearch = useCallback(
      (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter" && onSearch) {
          onSearch(search);
        }
      },
      [search, onSearch]
    );
    return (
      <Stack className="flex-row" gap={2}>
        {hasSearch && (
          <InputGroup>
            <Form.Control
              type="search"
              value={search}
              id="table-search"
              placeholder="Search"
              onChange={handleChange}
              onKeyDown={handleSearch}
            />
            <InputGroup.Text
              id="search"
              onClick={() => onSearch && onSearch(search)}
            >
              <SearchIcon color="grey" width="18" />
            </InputGroup.Text>
          </InputGroup>
        )}
        {hasDownload && (
          <Button
            title="Download"
            variant="light"
            size="sm"
            onClick={onDownload}
          >
            <DownloadIcon color="grey" width="18" />
            {/* <ArrowDownTrayIcon width={18} /> */}
          </Button>
        )}
        {hasAddRow && (
          <Button title="Add Row" variant="light" size="sm" onClick={onAddRow}>
            <PlusIcon color="grey" width={18} />
          </Button>
        )}
        {children}
      </Stack>
    );
  }