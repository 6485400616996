import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import TopNav from "../components/TopNav";
import styles from "./index.module.scss";

const HEADER_MAP  = {
  '/':'Home',
  '/dashboard': 'Dashboard',
  '/adv-search': 'Advanced Search',
  // '/control-table': 'Control Table',
  '/admin': 'Admin',
  '/functional-admin': 'Functional Admin',
  '/monitoring-dashboards': 'Monitoring Dashboards',
  '/coverage':'Coverage'
};

const MainLayout = ({children}) => {
  const {pathname} = useLocation();
  return (
    <Container fluid>
      <Row>
        <TopNav />
        <Header />
      </Row>

      <Container>
        <div className={styles.main_layout}>
          <div className={styles.facet_headers}>
            <h3 className="page-title text-uppercase font-weight-normal">
            {HEADER_MAP[pathname] ?? 'Faled To Load Name'}
            </h3>
            <h3 className="page-title text-uppercase font-weight-normal">
              {process.env.REACT_APP_ENV}
            </h3>
          </div>
          <hr />
          {children}
        </div>
      </Container>
    </Container>
  );
}


export default MainLayout;
