import { createUploadFile } from "../../services/adminFileUploadService";
import { useEffect, useState } from "react";
import styles from "./index.module.scss";
import CustomForm from "../AdminForms/CustomForm";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Stack,
  ThemeProvider,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import CustomField from "../AdminForms/CustomField";

const fields = [
  // {
  //   label: "File Type",
  //   name: "filetype",
  //   type: "select",
  //   options: [
  //     { label: "StudentInitiation", value: "SI" },
  //     { label: "StudentSupplementaData", value: "SSD" },
  //     { label: "safi", value: "SAFI" },
  //   ],
  // },
  {
    label: "File Upload",
    name: "fileUpload",
    type: "file",
    placeholder: "Choose a file",
  },
];

function ManualFileUpload() {
  const [files, setFiles] = useState([]);
  const [valid, setValid] = useState(false);
  const [formError, setFormError] = useState(false);
  const [toast, setToast] = useState<null | string>(null);



  const handleSubmit = async (file) => {
    if (file) {
      const { name: fileName } = file;
      console.log('sd', fileName)
      console.log('sdsdc' , files[0].name)
      try {
        await createUploadFile("facet-uploadfiles-dev", fileName, file);
        console.log('true');
        setToast(`File ${fileName.split('.')?.[0] || ""} uploaded successfully!`);
      } catch (err) {
        setToast(`Failed to upload ${fileName.split('.')?.[0] || ""}`);
      }
    }
  };

  const onChange = (event) => {
    setFiles(event.target.files);
  };

  return (
    <div className="App">
      <Card className="m-2">  
      <ToastContainer position="top-center" >
      <Toast show={!!toast} onClose={() => setToast(null)}>
        <Toast.Header style={{ backgroundColor: "#274f82", color: "white" }}>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Successful</strong>
          <small></small>
        </Toast.Header>
        <Toast.Body>{toast}</Toast.Body>
      </Toast>
      </ToastContainer>   
        <Card.Body>
          <Row>
            <Col md={9} sm={12}>
              <Card.Title className="mb-3"></Card.Title>
              <h5>Manual Upload for SI, SSD, SAFI Files</h5>
              <p>Please select the file formats Ex: <small>SIS_STUINIT, SIS_SSD,SIS_SAFI</small></p>
            </Col>
          </Row>
          <Form
            validated={valid}
            onSubmit={(e: any) => {
              e.preventDefault();
              e.stopPropagation();
              let validFile = false;
              let validFileExtension = false;
              if (files[0] && files[0].name) {
                validFile = ["SIS_STUINIT", "SIS_SSD", "SIS_SAFI"].some(
                  (startStr) => files[0].name.startsWith(startStr) && (files[0].name.endsWith('.csv') || files[0].name.endsWith('.txt'))
                );
               
              }
              if (validFile) {
                setValid(false);
                setFormError(false);
                handleSubmit(files[0]);
                setFiles([])
              } else {
                setValid(false);
                setFormError(true);
              }
            }}
            className="mt-5"
          >
            <Row className="mb-3">
              <CustomField
                md={12}
                {...fields[0]}
                accept=".csv,.txt"
                onChange={onChange}
                value={
                  files?.map ? files?.map((file) => file?.name) : undefined
                }
              />
            </Row>
           <Row>
            <Col md={5} sm={12}></Col>
            <Col md={7} sm={12}>
            {formError && (
              <p className={styles.pos_error}>
               Please select/choose the .csv or .txt files
              </p>
            )}
            </Col>
           </Row>
            <Stack
              gap={2}
              className="justify-content-md-end justify-content-center flex-row"
            >
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Stack>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default ManualFileUpload;
