import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./index.module.scss";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Stack,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import CustomField from "../AdminForms/CustomField";
import CustomModal from "../AdminForms/CustomModal";
import { AppContext } from "../../common/AppProvider";
import CustomForm from "../AdminForms/CustomForm";
import {
  updateControlledClass,
  getsafissd,
  createControlledClasses,
  getunexssd,
} from "../../services/controltable";
import moment from "moment";
import Select, { Options } from "react-select";
import useSSIDData, { SAFI_ACTIONS } from "./useSSIDData";
import Checkbox from "react-custom-checkbox";
import * as Icon from "react-icons/fi";


type YearOption = { label: string; value: string };

export const UnexSSD = () => {
  const { data } = useContext(AppContext);





  const ref = useRef();
  const currAcdYear = `${moment().format("YYYY")}-${moment()
    .add(1, "years")
    .format("YYYY")}`;

 
  const [modifiedDict, setModifiedDict] = useState(false);
  const [year, setYear] = useState<YearOption | null>({
    label: currAcdYear,
    value: currAcdYear,
  });
  const [effDate, setEffDate] = useState<string | undefined>(undefined);
  //  const [safiEnabled, setSafiEnabled] = useState<string | null>(null);
  const [safiEnabled, setSafiEnabled] = useState(false);
  const [safiEnabledSsd, setSafiEnabledSsd] = useState(false);
  // const [clearUnexSafiData, setclearUnexSafiData] = useState(false);
  const [toast, setToast] = useState<null | string>(null);
  const [loading, setLoading] = useState(false);
  const [apiData, setApiData] = useState<any>({});
  useEffect(() => {
    if (Object.keys(apiData).length) {
      setSafiEnabled(apiData.enable_safi_control === "Y");
      setSafiEnabledSsd(apiData.enable_ssd === "Y");
      setEffDate(apiData.dictionary.effectiveDate)
    }
  }, [apiData]);

  console.log("safiEnabled ==>", safiEnabled);


   function clearUnexSafiData() {
    setSafiEnabled(false);
    setSafiEnabledSsd(false);
    setEffDate(" ");
  }

  useEffect(() => {
    if (year) {
      clearUnexSafiData();
      fetchUnexSafiData(year.value)
    }
  }, [year]);


  const onEditRow = () => {
    try{
    const postData = {
      ...apiData,
      dictionary: { effectiveDate: effDate },
      updated_at: moment().toISOString(),
      updated_by: data?.login["custom:displayName"],
      enable_safi_control: safiEnabled ? "Y" : "N",
      enable_ssd: safiEnabledSsd ? "Y" : "N"
    };
    updateControlledClass(postData).then((() => {
      fetchUnexSafiData(year.value)
    }))
    if (safiEnabled) {
      setToast(`Unex controls saved successfully!`);
    }
  }
  catch (err) {
    setToast(`Flag disabled`);
  }
  };

  const academicYears: YearOption[] = [
    `${moment().subtract(1, "years").format("YYYY")}-${moment().format(
      "YYYY"
    )}`,
    `${moment().format("YYYY")}-${moment().add(1, "years").format("YYYY")}`,
    `${moment().add(1, "years").format("YYYY")}-${moment()
      .add(2, "years")
      .format("YYYY")}`,
  ].map((y) => ({
    value: y,
    label: y,
  }));


  async function fetchUnexSafiData(year) {
    setLoading(true);
    const data = await getunexssd(year);
    if (data.resData?.data) {
      setApiData(data.resData?.data[0] || []);
      console.log("fecthed ==> ", data.resData?.data[0]);
    }
    setLoading(false);
  }

  const academicYearDropDown = useMemo(() => {
    return (
      <div
        className="d-flex my-3 mx-1 align-items-center"
        style={{ gap: "10px", flex: 1 }}
      >
        <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Academic Year :
        </label>
        <Select
          isClearable
          onChange={setYear}
          name="year"
          options={academicYears as any}
          value={year}
          required
          placeholder="Select Academic Year"
        />
       <div className={styles.fieldWrapper}>
       <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Enable Safi Control?
        </label>
        <Checkbox
          checked={safiEnabled}
          icon={<Icon.FiCheck color="#174A41" size={20} />}
          style={{ cursor: "pointer" }}
          borderColor="#5b8cb2"
          onChange={setSafiEnabled}
        />
        <div className={styles.ml1}/>
       </div>
       <div className={styles.fieldWrapper}>
       <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          Enable SSD?
        </label>
        <Checkbox
          checked={safiEnabledSsd}
          icon={<Icon.FiCheck color="#174A41" size={14} />}
          style={{ cursor: "pointer" }}
          borderColor="#5b8cb2"
          onChange={setSafiEnabledSsd}
        />
       </div>
      </div>
    );
  }, [
    year,
    modifiedDict,
    safiEnabled,
    safiEnabledSsd
  ]);

  const effectiveDate = useMemo(() => {
    return (
      <div
        className="d-flex my-3 mx-1 align-items-center"
        style={{ gap: "10px", flex: 1 }}
      >
        <label
          className="text-muted"
          style={{
            fontSize: 12,
            fontWeight: 600,
            fontFamily: "Roboto, sans-serif",
          }}
        >
          SAFI Effective Date :
        </label>
        <input type="date" onChange={(e) => setEffDate(moment(e.target.value).format('YYYY-MM-DD'))} value={effDate && effDate!== " " ? moment(effDate).format('YYYY-MM-DD') : effDate} />
      </div>
    );
  }, [
    effDate
  ]);

  return (
    <div className="App">
      <Card className="m-2"> 
        <ToastContainer position="top-center">
          <Toast show={!!toast} onClose={() => setToast(null)}>
            <Toast.Header
              style={{ backgroundColor: "#274f82", color: "white" }}
            >
              <img
                src="holder.js/20x20?text=%20"
                className="rounded me-2"
                alt=""
              />
              <strong className="me-auto">Successful</strong>
              <small></small>
            </Toast.Header>
            <Toast.Body>{toast}</Toast.Body>
          </Toast>
        </ToastContainer> 
        <Card.Body>
          <Row>
            <Col md={9} sm={12}>
              <Card.Title className="mb-3"></Card.Title>
              <Row>{academicYearDropDown}</Row>
              <Row>{effectiveDate}</Row>
            </Col>
          </Row>
            <Stack
              gap={2}
              className="justify-content-md-end justify-content-center flex-row"
            >
              <div
        className="d-flex justify-content-end align-items-center"
        style={{ gap: "10px", flex: 1 }}
      >
        <div
          className="d-flex justify-content-start align-items-center text-muted"
          style={{ gap: "10px", flex: 1 }}
        >
          <label
            className="text-muted"
            style={{
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "Roboto, sans-serif",
            }}
          >
            Last updated by:
          </label>
          <label
            className="text-muted"
            style={{ fontSize: 12, fontFamily: "Roboto, sans-serif" }}
          >
            {" "}
            {apiData.updated_by}
          </label>

          <label
            className="text-muted"
            style={{
              fontSize: 12,
              fontWeight: 600,
              fontFamily: "Roboto, sans-serif",
            }}
          >
            at:
          </label>
          <label
            className="text-muted"
            style={{ fontSize: 12, fontFamily: "Roboto, sans-serif" }}
          >
            {apiData.updated_at}
          </label>
        </div>
         <Button size="sm" onClick={() => onEditRow()} disabled={!year}>
           Update Unex Controls
        </Button> 
         {/* {modifiedDict && (
          <Button size="sm" onClick={onEditRow}>
            Save All Controls
          </Button>
        )}
        {!modifiedDict && (
          <Button size="sm" onClick={onEditRow}>
            Save All Controls
          </Button>
        )} */}
      </div>
      </Stack>
        </Card.Body>
      </Card>
    </div>
  );
}

 