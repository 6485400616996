import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
  createControlledFlagTable,
  // getControlledFlagTable,
  getControlledFlagTableUID,
  updateControlledFlagTable,
} from "../../services/controlflagtable";
import { PencilIcon, TrashIcon } from "@heroicons/react/outline";
import CustomTable, { CustomColumn } from "../../components/CustomTable";
import styles from "./index.module.scss";
import { Button, Form, Stack } from "react-bootstrap";
import CustomField from "./CustomField";
import CustomModal from "./CustomModal";
import { AppContext } from "../../common/AppProvider";
import { PageLoader } from "../../components/Common";


export const ControlFlags = () => {
  const { data } = useContext(AppContext);
  // const columns: any = [

  // ];
  const columns: Array<CustomColumn> = [
    {
      name: "UID",
      label: "UID",
      cellClass: `${styles.uid1_col}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      width: 90
    },
    {
      name: "FLAG_NAME",
      label: "Flag Name",
      cellClass: `${styles.flagname_col}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
    },
    {
      name: "FLAG_VALUE",
      label: "Flag Value",
      cellClass: `${styles.flagvalue_col}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      width:90
    },
    {
      name: "Academic_Year",
      label: "Academic Year",
      cellClass: `${styles.academicyear_col}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
      width:100
    },
    {
      name: "Createddate",
      label: "Created Date",
      cellClass: `${styles.createddate_col}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
    },
    {
      name: "Updatedby",
      label: "Updated by",
      cellClass: `${styles.updatedby_col}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
    },
    {
      name: "Updateddate",
      label: "Updated Date",
      cellClass: `${styles.updateddate_col}`,
      // headerClass: `${styles.text_ellipsis}`,
      showInExcel: true,
    },
    {
      name: "Actions",
      label: "Actions",
      // headerClass: `${styles.text_ellipsis}`,
      headerClass: "text-center",
      renderRow: (_, row, idx) => rowActions(row, idx),
      width: 70
    },
  ];
  const ref = useRef();
  const pageRef = useRef();

const [searchResults, setSearchResults] = useState<[]>();
console.log('search', searchResults)
  const [flagsdata, setFlagsData] = useState([]);
  const [showModal, setShowModal] = useState(null);
  const [currData, setCurrData] = useState({});
  const [currIndex, setCurrIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState(null);

  // async function fetchFlags() {
  //   setIsLoading(true);
  //   const data = await getControlledFlagTable();
  //   if (data.resData?.data) {
  //     setFlagsData(data.resData.data);
  //   }
  //   setIsLoading(false);
  // }

  // useEffect(async () => {
  //   await getControlledFlagTableUID(searchKey)
  // }, []);

  // const createRow  =  async(payload) => {
  //   const data = await createControlledFlagTable(JSON.stringify(payload))
  //   console.log(data)
  // }
  // const updateRow = async (payload) => {
  //   const data = await updateControlledFlagTable(payload)
  //   console.log('RES',data)
  // }

  const handleCurrValChange = (e) => {
    setCurrData((prev) => {
      prev[e.target.name] = e.target.value;
      return { ...prev };
    });
  };
  const handleSearch = async(e) => {
      // https://api.dev.facet.it.ucla.edu/transactions/controlflags/999380302
      setSearchKey(e ? e: null)
      if(e){
       setIsLoading(true);
       setTimeout(() => {
         (pageRef.current as any)?.setPage(1)
        });
      // const res = await getControlledFlagTableUID(e);
      // const data = res.resData?.data
      // setSearchResults(data)
      updateFalgsTable(e)
    }else{
      setSearchResults([])
      setSearchKey(null)
    }
    }


  // const onDeleteRow = (index) => {
  //   setFlagsData((prev) => {
  //     prev.splice(index, 1);
  //     return [...prev];
  //   });
  // };

  const onEditRow = (index, row) => {
    setCurrData({ ...row });
    setCurrIndex(index);
    setShowModal("Edit");
  };

  const handleAddRow = () => {
    setShowModal("Add");
  };

  const rowActions = useCallback((row, index) => {
    return (
      <Stack className="flex-row justify-content-center" gap={2}>
        <Button
          title="Edit Row"
          size="sm"
          onClick={() => onEditRow(index, row)}
          className="bg-transparent border-0"
        >
          <PencilIcon color="grey" width={18} />
        </Button>
        {/* {row.isNewRow && ( */}
        {/* <Button
          title="Delete Row"
          size="sm"
          onClick={() => onDeleteRow(index)}
          className="bg-transparent border-0"
        >
          <TrashIcon color="grey" width={18} />
        </Button> */}
        {/* )} */}
      </Stack>
    );
  }, []);

  const onModalClose = () => {
    setCurrData({});
    setCurrIndex(null);
    setShowModal(null);
  };

  const updateFalgsTable = useCallback(async (key:string = searchKey) => {
    const res = await getControlledFlagTableUID(key)
    setIsLoading(false);
    const data = res.resData?.data
    setSearchResults(data)
  }, [searchKey])

  const onModalSave = useCallback(async () => {
    try {
      if (showModal === "Edit") {
        if (currIndex === null) {
          throw new Error("Missing edited row index!");
        }
        let Updatedby = data?.login["custom:displayName"]
        const payload: any = { ...currData, Updatedby };
        console.log(payload);
        delete payload.modified;
        delete payload.isNewRow;
        await updateControlledFlagTable(payload);
        setFlagsData((prev) => {
          prev[currIndex] = { ...currData, modified: true } as any;
          return [...prev];
        });
        // await fetchFlags();
        updateFalgsTable()
        
      } else {
        const payload: any = { ...currData };
        console.log(payload);
        delete payload.modified;
        delete payload.isNewRow;
        await createControlledFlagTable(JSON.stringify(payload));
        // console.log("", data);
        setFlagsData((prev: any) => {
          return [{ ...currData, isNewRow: true }, ...prev];
        });
        // await fetchFlags();
        updateFalgsTable()
      }
      onModalClose();
    } catch (err) {
      console.error(err);
      onModalClose();
    }
  }, [currData, currIndex, showModal]);

  return (
    <div className="control-flags-table">
      <CustomTable
        pagination="default"
        parentClass={styles.fullTableWidth as any}
        noRowsText={searchKey ? undefined : "Please search with UID above"}
        isLoading={isLoading}
        rows={searchResults || []}
        columns={columns}
        bordered
        ref={ref}
        pageRef={pageRef}
        rowsPerPage= {process.env.REACT_APP_ROWS_PAGE}
        rowClass="test"
        responsive
        striped
        title="Control Flags Table"
        style={{tableLayout: 'fixed'}}
        hasTableActions
        tableActionProps={{ onAddRow: handleAddRow, onSearch: handleSearch }}
        // headerCellClass={styles.table1_header_cell}
        // bodyCellClass={styles.table_body_cell}
        // bodyRowClass={styles.table_row_cell}
      />
      <CustomModal
        title={`${showModal} Data`}
        show={showModal}
        onClose={onModalClose}
        onSave={onModalSave}
      >
        <Form>
          <Stack className="mb-3" gap={3}>
            {showModal !== "Edit" && (
              <CustomField
                md={12}
                onChange={handleCurrValChange}
                label="UID"
                name="UID"
                type="text"
                value={currData["UID"]}
                required
              />
            )}
            {showModal !== "Edit" && (
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Flag Name"
              name="FLAG_NAME"
              type="select"
              hasDefault
              options={[
                { label: "SSD_OVERRIDE", value: "SSD_OVERRIDE" },
                { label: "STOP_PROCESSING", value: "STOP_PROCESSING" },
                { label: "LIMITED_PROCESSING", value: "LIMITED_PROCESSING" },
                { label: "SAFI_OVERRIDE", value: "SAFI_OVERRIDE" },
                { label: "DEMOGRAPHICS_OVERRIDE", value: "DEMOGRAPHICS_OVERRIDE" },
              ]}
              value={currData["FLAG_NAME"]}
              required
            />
            )}
            <CustomField
              md={12}
              onChange={handleCurrValChange}
              label="Flag Value"
              name="FLAG_VALUE"
              type="select"
              hasDefault
              options={[
                { label: "True", value: "T" },
                { label: "False", value: "F" },
              ]}
              value={currData["FLAG_VALUE"]}
              required
            />
            {showModal !== "Edit" && (
              <CustomField
                md={12}
                onChange={handleCurrValChange}
                label="Academic Year"
                name="Academic_Year"
                type="text"
                value={currData["Academic_Year"]}
                required
              />
            )}
          </Stack>
        </Form>
      </CustomModal>
      {/* <PageLoader isLoading={isLoading} /> */}
    </div>
  );
};
