import React from "react";
import styles from "./index.module.scss";
import { Button, Container, Row, Stack } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import TopNav from "../../components/TopNav";

export default function ErrorPage() {
  const history = useHistory();

  return (
    <>
      <Container fluid>
        <Row>
          <TopNav />
        </Row>
        <Stack
          gap={4}
          className={`align-items-center justify-content-center card m-3 p-3 ${styles.paper_shadow}`}
        >
          <h2>Something went wrong !</h2>
          <h5>Please Contact FACET Administrator at:<a href="mailto:facetintegration@it.ucla.edu"> facetintegration@it.ucla.edu</a></h5>       
          {/* <Button size="sm" onClick={() => history.push("/")}>
            Go Home
          </Button> */}
        </Stack>
      </Container>
    </>
  );
}
