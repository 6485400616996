
const CloudWatchDashboard  = ({  }) => {
   
  
    return (
      <a target="_blank" href='https://us-west-2.console.aws.amazon.com/cloudwatch/home?region=us-west-2#dashboards/dashboard/facet-Integ-dashboard-dev'>
      Go to Monitoring Dashboards
      
      </a>
    );
  };      
export default CloudWatchDashboard;
