import { Container } from "react-bootstrap";
import Menu from "../Menu";
import styles from "./index.module.scss";

const Header = () => {
  return (
    <div className={styles.header}>
      <Container>
        <div className={styles.header__wrapper}>
          <h1 className={styles.header__logo}>UCLA</h1>
          <Menu />
        </div>
      </Container>
    </div>
  );
};

export default Header;
