import { useState } from "react";
import { Tab, TabProps, TabsProps } from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";

interface ITabProps extends TabProps {
  Component: React.FunctionComponent;
}
interface ICustomTabs extends TabsProps {
  tabs: ITabProps[];
}
const CustomTabs = ({ tabs, defaultActiveKey='' }: ICustomTabs) => {
  const [active, setActive] = useState<string>(defaultActiveKey as string)
  return (
    <Tabs defaultActiveKey={defaultActiveKey} onSelect={setActive}>
      {tabs.map(({ title, Component: Comp, eventKey }) => (
        <Tab key={eventKey} eventKey={eventKey} title={title}>
          {active === eventKey && <Comp/>}
        </Tab>
      ))}
    </Tabs>
  );
};

export default CustomTabs;
