import React, { Component, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { PageLoader, NotFound } from "./components/Common";
import { ROUTES } from "./common/constants";
import Dashboard from "./Pages/Dashboard";

import "./styles/styles.scss"; // global styles
import Login from "./Pages/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import Classes from "./Pages/Classes";
import FunctionalAdmin from "./Pages/FunctionalAdmin";
import AdvSearch from "./Pages/AdvSearch";
import CloudWatchDashboard from "./Pages/CloudWatchDashboard";
import AdminForms from "./Pages/AdminForms";
import "bootstrap/dist/css/bootstrap.css";
import ErrorPage from "./Pages/ErrorPage";
import Home from "./Pages/Home";
import TopNav from "./components/TopNav";
import AppProvider from "./common/AppProvider";
import { ThemeProvider } from "react-bootstrap";
import Coverage from "./Pages/Coverage";

const App = () => {
  return (
    <ThemeProvider>
      <AppProvider>
        <Router>
          <Suspense fallback={<PageLoader />}>
            <Switch>
              <ProtectedRoute route={Home} exact path={ROUTES.APP_ROOT} />
              {/* <ProtectedRoute route={Classes} exact path={ROUTES.CLASSES} /> */}
              <ProtectedRoute
                route={Dashboard}
                exact
                path={ROUTES.DASHBOARD}
              />
              <ProtectedRoute
                route={AdvSearch}
                exact
                path={ROUTES.ADV_SEARCH}
              />
              <ProtectedRoute
                route={AdminForms}
                exact
                path={ROUTES.ADMIN_FORMS}
              />
              <ProtectedRoute
                route={FunctionalAdmin}
                exact
                path={ROUTES.FUNCTIONAL_ADMIN}
              />
              <ProtectedRoute
                route={CloudWatchDashboard}
                exact
                path={ROUTES.MONITORING_DASHBOARDS}
              />
              <ProtectedRoute
                route={Coverage}
                exact
                path={ROUTES.COVERAGE}
              />
              <Route exact path={ROUTES.LOGIN} component={Login} />
              <Route exact path={ROUTES.ERROR} component={ErrorPage} />
              {/* <Route exact path={ROUTES.SUMMARY} component={Summary} /> */}
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </AppProvider>
    </ThemeProvider>
  );
};

export default App;
