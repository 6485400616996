const Home = () => {
    return (
        <p
            className="text-muted text-center"
            style={{ fontSize: 18, fontWeight: 600,fontFamily: "sans-serif" }}
          >
              Welcome to Facet Integrations Dashboard Application.
             <h6>Please Contact IT Services - Facet Integration at:<a href="mailto:facetintegration@it.ucla.edu"> facetintegration@it.ucla.edu</a></h6>
          </p>
      );
};
export default Home;